<template>
    <div class="demo">
        <iframe id="dashboardIframe"></iframe>
    </div>
</template>

<style scoped lang="scss">
.demo {
    display: flex;
    height: 100%;
    width: 100%;
    color: #333;
    text-shadow: none;
    justify-content: center;
    align-items: center;

    iframe {
        width: 100%;
        height: 100%;
        border: none;
    }
}
</style>
<script>
export default {
    data() {
        return {
            iframe: ''
        };
    },
    props: {
        app: Object
    },
    watch: {
        app() {
            const token = localStorage.getItem('access_token') || '';

            this.iframe.contentWindow.postMessage({ isSDMessage: true, token }, '*');
        }
    },
    created() {
        this.$emit('api', {
            event: 'windowMaxSize'
        });
    },
    mounted() {
        this.iframe = document.getElementById('dashboardIframe');

        // 优先读取缓存
        const value = localStorage.getItem(this.app.key);
        if (value) {
            this.iframe.src = value;
        } else {
            this.iframe.src = this.app.url;
        }

        const token = localStorage.getItem('access_token') || '';

        setTimeout(() => {
            this.iframe.contentWindow.postMessage({ isSDMessage: true, token }, '*');
        }, 3000);
    }
};
</script>
